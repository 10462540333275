/* src/components/MainLayout.css */
.layout-container {
  position: relative;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px - 18px);
  width: 100vw;  /* Full viewport width */
  overflow: hidden; /* Prevent scrollbars from appearing due to the video */
}

.video-background {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%; /* Full height for the video */
  width: 100%;  /* Full width for the video */
  z-index: -1; /* Place video behind the content */
  overflow: hidden;
}

.video-background video {
  height: 100%; /* Ensure video covers the full height */
  width: 100%;  /* Ensure video covers the full width */
  object-fit: cover; /* Maintain aspect ratio and cover the container */
}

.overlay-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%; /* Full height of the viewport */
  max-height: calc(100vh - 60px - 18px);
  justify-content: space-between; /* Header at top, footer at bottom */
  align-items: center;
  flex: 1 1 auto;
  width: 100%;
  border-radius: 10px; /* Optional rounded edges */
  padding-top: 150px; /* Matches the height of the header */
  padding-bottom: 40px; /* Matches the height of the footer */
  padding-right: 0%;
  padding-left: 0%;
}

.overlay-content {
  max-width: 800px;
  position: absolute;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}
