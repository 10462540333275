/* src/components/Header.css */

.header {
  background-color: #1f2937;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px; /* Add vertical padding */
  height: auto; /* Let the height adjust based on content */
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo {
  height: 80px;
  margin-top: 5px;
}

.nav-links {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
  justify-content: flex-end; /* Align items to the right */
  flex-grow: 1; /* Allow nav to take up available space */
  flex-wrap: wrap; /* Allow items to wrap on smaller screens */
}

.nav-links li {
  margin: 0 10px; /* Adjust as needed to prevent overflow */
}

/* Ensure nav fills the remaining space */
nav {
  flex-grow: 1;
}

.nav-links a {
  text-decoration: none;
  color: #ffffff;
}

.nav-links a:hover {
  text-decoration: underline;
}

/* Tagline (optional) */
.tagline {
  margin-left: 10px;
  font-size: 1em;
  color: #ffffff;
}

.hamburger {
  display: none;
  font-size: 1.5em;
  cursor: pointer;
}

@media (max-width: 800px) {
  .hamburger {
    display: block;
  }

  .tagline {
    display: none;
  }

  .nav-links {
    flex-direction: column;
    background-color: #1f2937;
    position: absolute;
    top: 60px;
    right: 0;
    width: 100%;
    display: none; 
  }

  .nav-links li {
    margin: 10px 0;
    text-align: center;
  }
}

* {
  box-sizing: border-box;
}
