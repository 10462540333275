/* src/App.css */
body {
  margin: 0;
  padding: 0;
  font-family: 'Quantico', Arial, sans-serif;
  background-color: #f9f9f9;
}

a {
  text-decoration: none;
  color: inherit;
}

h1, h2, h3, h4, h5, h6 {
  color: #1f2937;
}

p {
  color: #4b5563;
  line-height: 1.6;
}

.main-content {
  padding-top: 110px; /* Same as header height */
}
