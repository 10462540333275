/* src/components/Footer.css */
.footer {
  background-color: #1f2937;
  color: #ffffff;
  text-align: center;
  padding: 10px 0;
  position: static;
  width: 100%;
  bottom: 0;
}

